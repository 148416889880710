<template>
    <b-modal title="Cetak Mutasi Aset" id="print-modal" no-close-on-backdrop no-close-on-esc>
        <b-form-group label="Tanggal Awal" class="mb-2">
            <b-form-input type="date" v-model="form.tgl_awal"></b-form-input>
        </b-form-group>
        <b-form-group label="Tanggal Akhir" class="mb-2">
            <b-form-input type="date" v-model="form.tgl_akhir"></b-form-input>
        </b-form-group>
        <b-form-group label="Asal Mutasi" v-if="myGudang" class="mb-2">
            <b-form-input disabled :value="myGudang.nama_gudang"></b-form-input>
        </b-form-group>
        <b-form-group label="Tujuan Mutasi" class="mb-2">
            <v-select :options="gudangOptions" v-model="form.id_gudang_tujuan" :reduce="option => option.value" label="text">
            </v-select>
        </b-form-group>
        <template #modal-footer>
            <b-button variant="primary" :disabled="!isValidForm" @click.prevent="submit">Cetak</b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BModal, BFormGroup, BFormInput, BButton} from 'bootstrap-vue'
    import vSelect from 'vue-select'
    export default {
        components: {
            BModal, BFormGroup, BFormInput, BButton,
            vSelect
        },
        data: () => ({
            form: {
                tgl_awal: null,
                tgl_akhir: null,
                id_gudang: null,
                id_gudang_tujuan: null
            }
        }),
        computed: {
            isValidForm() {
                if(!this.form.tgl_awal || !this.form.tgl_akhir || !this.form.id_gudang_tujuan) {
                    return false
                }

                return true
            },
            gudangOptions() {
                return this.$store.state.asset.gudang_tujuans
            }
        },
        methods: {
            submit() {
                if(!this.isValidForm) {
                    this.displayError({
                        message: 'Harap lengkapi form cetak mutasi!'
                    })
                    return false
                }

                if(this.myGudang) this.form.id_gudang = this.myGudang.id
                const params = this.form
                this.$emit('onPrint', params)
            }
        }
    }
</script>