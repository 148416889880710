var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "size": "lg",
      "id": "form-mutasi-request"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('b-button', {
          attrs: {
            "variant": "primary",
            "disabled": !_vm.isValidForm
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              return _vm.submit($event);
            }
          }
        }, [_vm._v("Simpan")])];
      },
      proxy: true
    }])
  }, [_c('b-form-group', {
    staticClass: "mb-2"
  }, [_c('label', [_vm._v("Pilih Tujuan Mutasi")]), _c('v-select', {
    attrs: {
      "options": _vm.gudangOptions,
      "label": "text",
      "reduce": function (option) {
        return option.value;
      }
    },
    model: {
      value: _vm.form.tujuan,
      callback: function ($$v) {
        _vm.$set(_vm.form, "tujuan", $$v);
      },
      expression: "form.tujuan"
    }
  })], 1), _c('b-form-group', {
    staticClass: "mb-2"
  }, [_c('label', [_vm._v("Pilih Aset")]), _c('v-select', {
    attrs: {
      "options": _vm.assetOptions,
      "label": "text",
      "reduce": function (option) {
        return option.value;
      }
    },
    model: {
      value: _vm.form.asset_id,
      callback: function ($$v) {
        _vm.$set(_vm.form, "asset_id", $$v);
      },
      expression: "form.asset_id"
    }
  })], 1), _vm.penyimpanans.length > 0 ? _c('b-form-group', {
    staticClass: "w-full",
    staticStyle: {
      "overflow": "auto"
    }
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Pilih Stok Aset")]), _c('b-table', {
    staticClass: "w-full",
    attrs: {
      "fields": _vm.fields,
      "items": _vm.penyimpanans
    },
    scopedSlots: _vm._u([{
      key: "cell(radio)",
      fn: function (_ref) {
        var item = _ref.item;
        return [item.stok > 0 ? _c('b-form-radio', {
          attrs: {
            "name": "stok",
            "value": item
          },
          model: {
            value: _vm.form.penyimpanan_dari,
            callback: function ($$v) {
              _vm.$set(_vm.form, "penyimpanan_dari", $$v);
            },
            expression: "form.penyimpanan_dari"
          }
        }) : _c('feather-icon', {
          staticClass: "text-danger",
          attrs: {
            "icon": "SlashIcon"
          }
        })];
      }
    }, {
      key: "cell(blok)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.blok ? item.blok.blok : '-') + " ")];
      }
    }, {
      key: "cell(jumlah)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [item.selected ? _c('b-form-input', {
          model: {
            value: _vm.form.jumlah,
            callback: function ($$v) {
              _vm.$set(_vm.form, "jumlah", $$v);
            },
            expression: "form.jumlah"
          }
        }) : _vm._e()];
      }
    }, {
      key: "cell(satuan)",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item.asset && item.asset.satuan ? item.asset.satuan.satuan : '') + " ")];
      }
    }], null, false, 3692839015)
  })], 1) : _c('i', {
    staticClass: "text-danger"
  }, [_vm._v("Stok Aset belum ada. Harap pilih aset lain")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }